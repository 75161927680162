@media print {
  .noPrint {
    display: none !important;
  }
  .forPrintUI {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-bottom: none !important;
  }
  .forPrintUI > div {
    border-right: none  !important;
  }
}
