h1 {
  text-align: center;
  font-weight: 500;
  font-size: 14px !important;
  color: #777;
}

.css-r2gao2 {
  border: none !important;
}

h1 span {
  font-weight: 600;
}

.container {
  width: 100%;
  /* padding: 50px 0; */
  /* margin: 50px auto; */
  position: relative;
  overflow: hidden;
}

.container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #abcbfe;
  background: white;
  margin-top: 10px;
  z-index: 9999;
  text-align: center;
  position: relative;
  left: 10px;
}

.marker.active {
  width: 20px;
  height: 20px;
  background: #abcbfe;
  left: 10px;
}
.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666;
}
i.fa.fa-check {
  display: none;
}
i.fa.fa-check.active {
  display: block !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 50%;
  color: #fff;
}

/* .timeline-content h3 {
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
}

.timeline-content span {
  font-size: 15px;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 14px;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
} */

.container:before {
  left: 19px;
  width: 2px;
}
.timeline-block {
  width: 100%;
  margin-bottom: 30px;
}
.timeline-block-right {
  float: none;
}

.timeline-block-left {
  float: none;
  direction: ltr;
}
