.grid-item {
  border-top: 1px dashed #bdbdbd;
  min-width: 0px;
}
.grid-item:first-child,
.grid-item:nth-child(2),
.grid-item:nth-child(-n + 3) {
  border-top: 1px dashed transparent;
}

.grid-item:nth-child(odd) {
  border-left: 0px;
}
.grid-item:nth-child(3n + 2) {
  border-left: 1px dashed #bdbdbd;
  border-right: 1px dashed #bdbdbd;
}
.status-grid,
.status-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
}
.status-grid-wrapper {
  grid-gap: 28px;
  justify-content: center;
}
.status-grid {
  grid-gap: 9px;
}
.grid-item.empty-middle {
  border-right: 1px dashed transparent !important;
}
.grid-item.element-count2 {
  border-right: 0 !important;
}
