.clear-all {
  color: white;
  height: 40px;
  width: 100px;
  border-radius: 20px !important;
  background-color: blue;
  align-items: center;
}

.form-field {
  padding: 20px !important;
}

.field-label {
  color: #1b254b !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
}

.frmb-control {
  color: #1b254b !important;
  font-weight: 500 !important;
  padding-left: 20px !important;
}

.frmb-control li{
  padding: 15px 10px !important;
  box-shadow: inset 0 0 0 1px #E2E8F0 !important;
}

.form-control {
  border: 1px solid #ccc;
}

.prev-holder > input[type = date]{
  width: 100% !important;
}

.form-wrap.form-builder .frmb .prev-holder input[type=date] {
  width: 100% !important;
  border: 1px solid #ccc;
}

.radio-group-field {
  min-height: 150px !important;
}

.select-field {
  min-height: 150px !important;
}

.prev-holder {
  padding-top: 5px;
  width: 100%;
  height: 3rem !important;
}

.form-control {
  border-color: #E2E8F0 !important;
}

.form-wrap.form-builder .stage-wrap.empty::after {
  font-size: 20px;
  color: #2b3674;
  font-weight: bold;
}

.form-actions {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  position: absolute !important;
  left: 0;
  display: none !important;
  padding: 20px !important;
}

h1 {
  font-size: 32px !important;
  font-weight: bolder !important;
}

h2 {
  font-size: 24px !important;
  font-weight: bolder !important;
}

h3 {
  font-size: 18.72px !important;
  font-weight: bolder !important;
}

h4 {
  font-size: 16px !important;
  font-weight: bolder !important;
}

h5 {
  font-size: 13.82px !important;
  font-weight: bolder !important;
}

h6 {
  font-size: 12px !important;
  font-weight: bolder !important;
}

/* .get-data {
  visibility: hidden;
} */

.save-template {
  color: white;
  height: 40px;
  width: 100px;
  border-radius: 20px !important;
  background-color: blue;
  align-items: center;
}
